/* eslint-disable no-irregular-whitespace */
import React, { useRef } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledAboutSection = styled.section`
  .inner {
    display: grid;
    // grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const InvestorCharter = () => {
  const revealContainer = useRef(null);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h1 style={{ marginBottom: 15 }}>Investor Charter</h1>

      <div className="inner">
        <StyledText>
          <div>
            <div>
              <h3>A. Vision and Mission Statements for investors.</h3>
              <p style={{ fontSize: '16px' }}>
                Vision - Invest with knowledge & safety Mission - Every investor should be able to
                invest in right investment products based on their needs, manage and monitor them to
                meet their goals, access reports and enjoy financial wellness.
              </p>
              <br />
            </div>

            <div>
              <h3>
                B. Details of business transacted by the Research Analyst with respect to the
                investors.
              </h3>
              <ol style={{ fontSize: '16px' }}>
                {[
                  `To publish research report based on the research activities of the RA. `,

                  `To provide an independent unbiased view on securities. `,

                  `To  offer  unbiased  recommendation,  disclosing  the  financial  interests  in recommended securities. `,

                  `To  provide  research  recommendation,  based  on  analysis  of  publicly available information and known observations.  `,

                  `To conduct audit annually`,
                ].map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>
            </div>

            <div>
              <h3>C. Details of services provided to investors (No Indicative Timelines)</h3>
              <ol style={{ fontSize: '16px' }}>
                {[
                  `Onboarding of Clients. `,

                  `Disclosure to Clients `,

                  `To distribute research reports and recommendations to the clients without discrimination.`,

                  `To maintain confidentiality w.r.t publication of the research report until made available in the public domain.`,
                ].map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>
            </div>

            <div>
              <h3>D. Details of grievance redressal mechanism and how to access it</h3>
              <p style={{ fontSize: '16px' }}>
                In case of any grievance / complaint, an investor should approach the concerned
                research analyst at{' '}
                <a href="mailto:vivekkhatri.ra@gmail.com" target="_blank" rel="noreferrer">
                  vivekkhatri.ra@gmail.com
                </a>{' '}
                and shall ensure that the grievance is resolved within 30 days.
              </p>
              <p style={{ fontSize: '16px' }}>
                If the investor’s complaint is not redressed satisfactorily, one may lodge a
                complaint with SEBI on SEBI’s SCORES portal which is a centralized web based
                complaints redressal system. SEBI takes up the complaints registered via SCORES with
                the concerned intermediary for timely redressal. SCORES facilitates tracking the
                status of the complaint.
              </p>
              <p style={{ fontSize: '16px' }}>
                With regard to physical complaints, investors may send their complaints to: Office
                of Investor Assistance and Education, Securities and Exchange Board of India, SEBI
                Bhavan. Plot No. C4-A, ‘G’ Block, Bandra-Kurla Complex, Bandra (E), Mumbai - 400
                051.
              </p>
              <br />
            </div>

            <div>
              <h3>E. Expectations from the investors (Responsibilities of investors)</h3>
              <div>
                <h6>Do’s </h6>
                <ol style={{ fontSize: '16px', marginTop: 0, paddingTop: 0 }}>
                  {[
                    `Always deal with SEBI registered Research Analyst.`,
                    `Ensure that the Research Analyst has a valid registration certificate. `,
                    `Check for SEBI registration number. `,
                    `Please  refer  to  the  list  of  all  SEBI  registered  Research Analysts which is  available on SEBI website  in the following link <br>(<a style="color: blue" href='https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&intmId=14' target='_blank'>https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&intmId=14</a>)`,
                    `Always pay attention towards disclosures made in the research reports before investing. `,
                    `Pay your Research Analyst through banking channels only and maintain duly signed receipts mentioning the details of your payments. `,
                    `Before  buying  securities  or  applying  in  public  offer,  check  for  the  research recommendation provided by your research Analyst. `,
                    `Ask all relevant questions and clear your doubts with your Research Analyst before acting on the recommendation. `,
                    `Inform SEBI about Research Analyst offering assured or guaranteed returns. `,
                  ].map((item, index) => (
                    <li key={index}>{parse(item)}</li>
                  ))}
                </ol>

                <h6>​Dont's</h6>
                <ol style={{ fontSize: '16px', marginTop: 0, paddingTop: 0 }}>
                  {[
                    `Do not provide funds for investment to the Research Analyst. `,
                    `Don’t fall prey to luring advertisements or market rumours. `,
                    `Do  not  get  attracted  to  limited  period  discount  or  other  incentive,  gifts,  etc. offered by Research Analyst.`,
                    `Do  not  share  login  credentials  and  password  of  your  trading  and  demat accounts with the Research Analyst. `,
                  ].map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ol>
              </div>
            </div>

            <div>
              <h3>Grievance Redressal </h3>
              <p style={{ fontSize: '16px' }}>
                Grievance Redressal Methods Client’s queries / complaints may arise due to lack of
                understanding or a deficiency of service experienced by clients. Deficiency of
                service may include lack of explanation, clarifications, understanding which
                escalates into shortfalls in the expected delivery standards, either due to
                inadequacy of facilities available or through the attitude of staff towards client.
                <ol style={{ fontSize: '16px', marginTop: 0, paddingTop: 0 }}>
                  {[
                    `Clients can seek clarification to their query and are further entitled to make a
                    complaint in writing.`,
                    `Clients can write to the research analyst at <a href="mailto:vivekkhatri.ra@gmail.com" style="color: blue" target="_blank">vivekkhatri.ra@gmail.com</a>. The client
                    can expect a reply within 10 business days of approaching research analyst.`,
                    `In case you are not satisfied with our response you can lodge your grievance with
                    SEBI at <a href='https://scores.sebi.gov.in' style="color: blue" target='_blank'>https://scores.sebi.gov.in</a> or you may also write to any of the offices of SEBI.
                    SCORES may be accessed thorough SCORES mobile application as well, same can be
                    downloaded from below link:
                    <a style="color: blue" href='https://play.google.com/store/apps/details?id=com.ionicframework.sebi236330' target='_blank'>https://play.google.com/store/apps/details?id=com.ionicframework.sebi236330</a>`,
                  ].map((item, index) => (
                    <li key={index}>{parse(item)}</li>
                  ))}
                </ol>
              </p>
            </div>

            <div>
              <br />
              <h3>Complaint log/status</h3>
              <p style={{ fontSize: '16px' }}>
                Formats for investors complaints data to be disclosed monthly by RAs on their
                website/mobile application:
              </p>

              <a href="Vivek_Khatri_Disclosure_April-24.pdf" target="_blank">
                <div
                  style={{
                    height: 100,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 32 32">
                    <path fill="#909090" d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945z" />
                    <path fill="#f4f4f4" d="M24.031 2H8.808v27.928h20.856V7.873z" />
                    <path fill="#7a7b7c" d="M8.655 3.5h-6.39v6.827h20.1V3.5z" />
                    <path fill="#dd2025" d="M22.472 10.211H2.395V3.379h20.077z" />
                    <path
                      fill="#464648"
                      d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2.042 2.042 0 0 0 .647-.117a1.427 1.427 0 0 0 .493-.291a1.224 1.224 0 0 0 .335-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.409-.104a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892"
                    />
                    <path
                      fill="#dd2025"
                      d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511m-2.357.083a7.543 7.543 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14.216 14.216 0 0 0 1.658 2.252a13.033 13.033 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.787 10.787 0 0 1-.517 2.434a4.426 4.426 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444M25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14.228 14.228 0 0 0-2.453.173a12.542 12.542 0 0 1-2.012-2.655a11.76 11.76 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.309 9.309 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.622 9.622 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a22.549 22.549 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035"
                    />
                    <path fill="#909090" d="M23.954 2.077V7.95h5.633z" />
                    <path fill="#f4f4f4" d="M24.031 2v5.873h5.633z" />
                    <path
                      fill="#fff"
                      d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2.042 2.042 0 0 0 .647-.117a1.428 1.428 0 0 0 .493-.291a1.224 1.224 0 0 0 .332-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.411-.105a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892"
                    />
                  </svg>
                  Vivek_Khatri_Disclosure_April-24.pdf
                </div>
              </a>
            </div>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default InvestorCharter;
