import React, { useRef } from 'react';
import styled from 'styled-components';

const StyledAboutSection = styled.section`
  .inner {
    display: grid;
    // grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const Disclaimer = () => {
  const revealContainer = useRef(null);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h1>Disclaimer</h1>

      <div className="inner">
        <StyledText>
          <div>
            <h4>Please read the following very carefully</h4>

            <ol>
              {[
                `Investments in securities market are subject to market risks. Read all the related
              documents carefully before investing.`,

                `Registration granted by SEBI and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors.`,

                `The fees is paid for research recommendations and is not refundable or cancellable under any circumstances.`,

                `We do not provide any guaranteed profit or fixed returns or any other services. We charge fixed fees and do not operate on any profit sharing model.`,

                `Images shared are for illustration purposes only.`,

                `We are not responsible for any financial loss or any other loss incurred by the client.`,

                `Please be fully informed about the risk and costs involved in trading and investing. Please consult your investment advisor before trading. Trade only as per your risk appetite and risk profile. `,

                `Trading in options is risky due to its volatile nature. Upon accepting our service, you hereby accept that you fully understand the risks involved in trading. `,

                `We advise the viewers to apply own discretion while referring testimonials shared by the client. Past performances and results are no guarantee of future performance.`,

                `All recommendations shared are confidential and for the reference of paid members only. Any unapproved distribution of sensitive data will be considered as a breach of confidentiality and appropriate legal action shall be initiated.`,

                `The recommendations must not be used as a singular basis of any investment decision. The views do not consider the risk appetite or the particular circumstances of an individual investor; readers are requested to take professional advice before investing and trading. Our recommendations should not be construed as investment advice.`,

                `In case of any query, please email on vivekkhatri.ra@gmail.com Be rest assured, our team will get back to you and resolve your query. Please state your registered phone number while mailing us.`,

                `Reports based on technical and derivative analysis center on studying charts of a stock's price movement, outstanding positions and trading volume, as opposed to focusing on a company's fundamentals and, as such, may not match with a report on a company's fundamentals.`,

                `The research analyst or research entity or his associate or his relative do not have financial interest in the subject company.`,

                `The research analyst or its associates or relatives, do not have actual/beneficial ownership of one per cent or more securities of the subject company, at the end of the month immediately preceding the date of publication of the research report or date of the public appearance. `,

                `The research analyst or his associate or his relative do not have any other material conflict of interest at the time of publication of the research report or at the time of public appearance. `,

                `The research analyst or its associates have not received any compensation from the subject company in the past twelve months.`,

                `The research analyst or its associates have not managed or co-managed public offering of securities for the subject company in the past twelve months.`,

                `The research analyst or its associates have not received any compensation for investment banking or merchant banking or brokerage services from the subject company in the past twelve months.`,

                `The research analyst or its associates have not received any compensation for products or services other than investment banking or merchant banking or brokerage services from the subject company in the past twelve months.`,

                `The research analyst or its associates have not received any compensation or other benefits from the subject company or third party in connection with the research report.`,

                `The research analyst has not been engaged in market making activity for the subject company.`,

                `The research analyst has not served as an officer, director or employee of the subject company.`,

                `The research analyst did not receive any compensation or other benefits from the companies mentioned in the documents or third party in connection with preparation of the research documents. Accordingly, research Analyst does not have any material conflict of interest at the time of publication of the research documents.`,
              ].map((item, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: '16px',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                  }}>
                  {item}
                </li>
              ))}
            </ol>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default Disclaimer;
