/* eslint-disable no-irregular-whitespace */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@components/icons';
import { socialMedia } from '@config';

const StyledFooter = styled.footer`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  height: auto;
  min-height: 70px;
  padding: 15px;
  text-align: center;
`;

const StyledSocialLinks = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    max-width: 270px;
    margin: 0 auto 10px;
    color: var(--black);
  }

  ul {
    ${({ theme }) => theme.mixins.flexBetween};
    padding: 0;
    margin: 0;
    list-style: none;

    a {
      padding: 10px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const StyledCredit = styled.div`
  color: var(--black);
  // font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  line-height: 1;

  display: flex;
  text-align: left;
  gap: 5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .github-stats {
    margin-top: 10px;

    & > span {
      display: inline-flex;
      align-items: center;
      margin: 0 7px;
    }
    svg {
      display: inline-block;
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
  }
`;

const Footer = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const contactUsLink = useMemo(
    () =>
      `mailto:vivekkhatri.ra@gmail.com?subject=[Contact Us] ${firstName} ${lastName}: ${email}&body=${message}`,
    [firstName, lastName, email, message],
  );

  return (
    <StyledFooter>
      <StyledSocialLinks>
        <ul>
          {socialMedia &&
            socialMedia.map(({ name, url }, i) => (
              <li key={i}>
                <a href={url} aria-label={name}>
                  <Icon name={name} />
                </a>
              </li>
            ))}
        </ul>
      </StyledSocialLinks>

      <StyledCredit tabindex="-1">
        <div
          style={{
            maxWidth: '16rem',
          }}>
          <h1>Let's Connect</h1>​
          <p style={{ fontSize: 18 }}>
            Hello, I'm Vivek Khatri, a seasoned professional in the world of finance and
            investments. As a registered Research Analyst with SEBI (Securities and Exchange Board
            of India) having registration number INH000013536, I bring a wealth of knowledge and
            expertise to the realm of market analysis.
          </p>
        </div>
        <div>
          <h1>Address</h1>
          <p style={{ fontSize: 18 }}>
            131 Tarangahill Society <br />
            Near Arjun Ashram <br />
            Chandlodia <br />
            Ahmedabad - 382481 <br />
            Gujarat, India
          </p>
          <p style={{ fontSize: 18 }}>
            <b>Email</b>
            <br />
            vivekkhatri.ra@gmail.com
          </p>
          <p style={{ fontSize: 18 }}>
            <b>Phone</b>
            <br />
            +91 8799167391
          </p>
        </div>
        <div>
          <h1>Contact Us</h1>​
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
            }}>
            <input
              type="text"
              placeholder="First Name"
              style={{ width: '100%', borderRadius: 5, borderColor: 'var(--green)', padding: 7 }}
              onChange={e => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last Name"
              style={{ width: '100%', borderRadius: 5, borderColor: 'var(--green)', padding: 7 }}
              onChange={e => setLastName(e.target.value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              flexDirection: 'row',
              marginTop: 5,
            }}>
            <input
              type="email"
              placeholder="Email"
              style={{ width: '100%', borderRadius: 5, borderColor: 'var(--green)', padding: 7 }}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              marginTop: 5,
            }}>
            <input
              type="text"
              placeholder="Message"
              style={{ width: '100%', borderRadius: 5, borderColor: 'var(--green)', padding: 7 }}
              onChange={e => setMessage(e.target.value)}
            />
          </div>
          <a
            href={contactUsLink}
            style={{
              backgroundColor: 'var(--green)',
              color: 'white',
              padding: '10px',
              borderRadius: 5,

              textDecoration: 'none',
              fontWeight: 'bold',
              fontSize: '1rem',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px',
              width: '100%',
              maxWidth: '200px',
              margin: '0 auto',
              marginBottom: '20px',
              marginTop: 5,
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: 'var(--green-dark)',
              },
              '&:active': {
                transform: 'scale(0.98)',
              },
              '&:focus': {
                outline: 'none',
              },
              '&:disabled': {
                opacity: 0.5,
                cursor: 'not-allowed',
              },
            }}>
            Submit
          </a>
        </div>
      </StyledCredit>
      <div
        style={{
          marginTop: 100,
          lineHeight: 0.5,
        }}>
        <h5 style={{ margin: 0 }}>Important Links:-</h5>
        <div style={{ lineHeight: 0.8 }}>
          {/* <p style={{ fontSize: 12 }}> */}
          <a
            href="https://smartodr.in/login"
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: 12 }}>
            ODR
          </a>
          {/* </p> */}
          <br />
          {/* <p style={{ marginTop: 0, fontSize: 12 }}> */}
          <a
            href="https://www.sebi.gov.in/legal/circulars/jul-2023/online-resolution-of-disputes-in-the-indian-securities-market_74794.html"
            target="_blank"
            rel="noreferrer"
            style={{ marginTop: 0, fontSize: 12 }}>
            ODR CIRCULAR
          </a>
          {/* </p> */}
        </div>
      </div>
    </StyledFooter>
  );
};

Footer.propTypes = {
  githubInfo: PropTypes.object,
};

export default Footer;
