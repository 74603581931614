import React, { useRef } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledAboutSection = styled.section`
  .inner {
    display: grid;
    // grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const PrivacyPolicy = () => {
  const revealContainer = useRef(null);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h1>Privacy Policy</h1>

      <div className="inner">
        <StyledText>
          <div>
            <h5>
              The provided text is a disclaimer and user agreement from VIVEK KHATRI, a SEBI
              registered Research Entity. Here is a summary of the key points:
            </h5>

            <ol>
              {[
                `<b>SEBI Registration</b>: VIVEK KHATRI is registered with SEBI (Securities and Exchange Board of India) as a Research Entity.`,

                `<b>Nature of Business:</b> The company provides fundamental and technical reports, including charts and tools, to identify market patterns for investors.`,

                `<b>Disclaimer on Information Accuracy:</b> The information and views in the reports are believed to be reliable, but the company does not guarantee their accuracy, completeness, or reliability. Investors are advised to independently evaluate market conditions and risks.`,

                `<b>Confidentiality:</b> The reports are confidential and intended solely for the selected recipient. Unauthorized distribution or alteration is prohibited without prior written consent.`,

                `<b>Data Source:</b> The information in the reports is derived from publicly available data, and the company does not guarantee the accuracy or completeness of the data.`,

                `<b>Risk Disclaimer:</b> The disclaimer emphasizes that investment in securities is subject to market risks, and past performance is not indicative of future results.`,

                `<b>Independence and Conflict of Interest:</b> The company strives to minimize conflicts of interest in preparing research reports. It discloses that it or its partners may have financial interests but did not receive compensation from the subject companies mentioned in the report.`,

                `<b>Ownership Disclosure:</b> The company and its partners do not collectively own 1% or more of the equity securities of the subject companies mentioned in the report.`,

                `<b>Disciplinary Action:</b> The document confirms that no material disciplinary action has been taken against the company by any regulatory authority impacting equity research activities.`,

                `<b>Website Disclaimer:</b> The website (https://www.vivekkhatri.in/) is not responsible for errors, omissions, or representations on its pages. It disclaims liability for risks associated with internet and SMS-based information dissemination.`,

                `<b>Warranty Disclaimer:</b> The website expressly disclaims any implied warranties and considers itself subject to the jurisdiction of the court of Gujarat in India.`,

                `<b>Geographical Limitation:</b> The website is specifically for users in the territory of India, and while access for users outside India is not denied, the website disclaims legal liabilities in jurisdictions other than India.`,

                `<b>User Authorization:</b> By using the website, users authorize VIVEK KHATRI to contact them and send promotional and transactional communication.`,

                `<b>Termination of Accounts:</b> The company reserves the right to terminate accounts of subscribers/customers violating proprietary rights.`,

                `<b>Communication Authorization:</b> Users authorize the company to contact them, even if registered under the National Do Not Call Registry or other related regulations.`,
              ].map((item, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: '16px',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                  }}>
                  {parse(item)}
                </li>
              ))}
            </ol>

            <h5>
              It is crucial for users and investors to carefully read and understand these
              disclaimers and agreements before using the services or relying on the provided
              information.
            </h5>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default PrivacyPolicy;
